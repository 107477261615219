import {useQuery} from "react-query";

import {ko, ok} from "../../../../utils/service.utils";
import {crudService} from "../../../../shared/services/firebase/db.firebase";
import {uploadFile} from "../../../../shared/services/firebase/storage.firebase";

const colName = 'home_pages';
export const {colRef, docRef, create, update, remove, findById, findAll} = crudService(colName);

const homePageDocId = "home-page";

export const saveHomePage = async (entity) => {
    try {
        if (!entity) return ko("Impossible de modifier un service nul");
        if (!entity.image || !entity.image.file) return ko("Impossible de créer la configuration générale sans l'image");
        if (!entity.backgroundImage || !entity.backgroundImage.file) return ko("Impossible de créer la configuration générale sans l'image de font");
        if (!entity.ticketImage || !entity.ticketImage.file) return ko("Impossible de créer la configuration générale sans l'image qui sera le ticket");

        const {
            image: {file: imageFile},
            backgroundImage: {file: backgroundImageFile},
            ticketImage: {file: ticketImageFile},
            id: entityId,
            ref,
            ...others
        } = entity;

        const {status: status1, entity: imageUrl, message: message1} = await uploadFile(colName, imageFile);
        if (!status1) return ko(message1);

        const {status: status2, entity: backgroundImageUrl, message: message2} = await uploadFile(colName, backgroundImageFile);
        if (!status2) return ko(message2);

        const {status: status3, entity: ticketImageUrl, message: message3} = await uploadFile(colName, ticketImageFile);
        if (!status3) return ko(message3);

        await update(homePageDocId, {...others, imageUrl, backgroundImageUrl, ticketImageUrl});
        return ok(entity, "Configuration générale modifié avec succès");
    } catch (e) {
        return ko("Erreur lors de la mise a jour de la configuration générale");
    }
}

export const useFindById = () => {
    const queryKey = ["FIND_PRESENTATION_DATA_BY_ID", homePageDocId];
    const queryFn = () => {
        if (!homePageDocId) return {};
        return findById(homePageDocId);
    }
    return useQuery(queryKey, queryFn, {});
}
