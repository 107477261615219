import {useEffect} from "react";
import {BACKOFFICE_PATH, SELF_CARE_PATH} from "../../paths";
import {CalendarMonth, LocationOn} from "@mui/icons-material";
import {EventGrid} from "../../backoffice/general/events/components/EventComponents";
import {Box, Card, CardContent, Grid, LinearProgress, Stack, Typography} from "@mui/material";
import {useSearchDepartures} from "../../backoffice/configuration/departures/departure.service";
import {EventTime, useSearchValidatedEvents} from "../../backoffice/general/events/event.service";

export default function EventsContainer() {
    return (
        <EventsWrapper hrefBuilder={event => SELF_CARE_PATH.events.display(event?.id)}/>
    )
}


export function SaleEventGrid() {
    return (
        <EventsWrapper hrefBuilder={event => BACKOFFICE_PATH.events.sale.display(event.id)}/>
    )
}

export function EventsWrapper({hrefBuilder}) {

    const {
        isLoading, departure, filteredEvents, availableDays, day, time, setDeparture, setDay, setTime
    } = useSearchValidatedEvents();

    return (
        <Stack gap={7} pt={5}>

            <DepartureSelector departure={departure} setDeparture={setDeparture}/>

            {!isLoading && <DaySelector availableDays={availableDays} day={day} setDay={setDay}/>}

            {!isLoading && <TimeSelector day={day} time={time} setTime={setTime}/>}

            {isLoading && <LinearProgress sx={{mb: 2}}/>}

            {!isLoading && (
                <Box id={"container"}>
                    <EventGrid
                        events={filteredEvents}
                        hrefBuilder={hrefBuilder}
                    />
                </Box>
            )}
        </Stack>
    );
}

function DepartureSelector({departure, setDeparture}) {
    const {data: departures, isLoading} = useSearchDepartures();

    if (isLoading) return <LinearProgress/>;

    if (!departures?.length) return <></>;

    return (
        <SelectorWrapper
            id={"departure"}
            targetId={"day"}
            title={"Selectionner un lieu de départ"}
            equalsFn={(x, y) => x?.id === y?.id}
            selectedValue={departure} onSelect={setDeparture}
            items={departures.map(departure => ({
                key: departure.id, label: departure.name, value: departure, Icon: LocationOn
            }))}
        />
    )
}

function DaySelector({availableDays, day, setDay}) {
    if (!availableDays?.length) return <></>;

    return (
        <SelectorWrapper
            id={"day"}
            targetId={"time"}
            title={"Selectionner un jour de départ"}
            selectedValue={day} onSelect={setDay}
            items={availableDays.map(availableDay => ({
                key: availableDay, label: availableDay, value: availableDay, Icon: CalendarMonth
            }))}
        />
    )
}

function TimeSelector({day, time, setTime}) {
    if (!day) return <></>;

    return (
        <SelectorWrapper
            id={"time"}
            targetId={"container"}
            title={"Selectionner un moment de départ"}
            selectedValue={time} onSelect={setTime}
            items={EventTime.values().map(time => ({
                key: time.name, label: time.label, value: time, Icon: time.Icon
            }))}
        />
    )
}

function SelectorWrapper(
    {
        id, title, items, onSelect, selectedValue, iconSize = 40, targetId,
        labelVariant = "h3", xs = 12, md = 6, equalsFn = (x, y) => x === y
    }
) {

    useEffect(() => {
        if (!selectedValue) return;

        scrollToById(targetId);

    }, [selectedValue, targetId]);


    return (
        <Stack gap={2} id={id}>
            <Stack alignItems={"start"}>
                <Typography variant={"h5"}>
                    {title}
                </Typography>
            </Stack>

            <Grid container spacing={3}>
                {items?.map(({key, value, label, Icon}) => {
                    const isSelected = equalsFn(value, selectedValue);
                    return (
                        <Grid key={key} item xs={xs} md={md}>
                            <Card
                                onClick={() => {
                                    onSelect(value);
                                }}
                                sx={{
                                    cursor: "pointer",
                                    border: isSelected && "1px solid #0070f3",
                                    boxShadow: isSelected && "none",
                                    color: isSelected && "#0070f3"
                                }}
                            >
                                <CardContent>
                                    <Stack
                                        spacing={1}
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={{xs: "start", md: "center"}}
                                    >
                                        <Icon sx={{fontSize: iconSize}}/>
                                        <Typography variant={labelVariant}>{label}</Typography>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Stack>
    );
}


export const scrollToById = (id) => {
    const element = document.getElementById(id);
    if (!element) return;

    element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
    });
}
