import {useMemo} from "react";
import {
    alpha,
    Avatar,
    Box,
    Card,
    CircularProgress,
    Container,
    Skeleton,
    Stack,
    styled,
    Typography
} from "@mui/material";

import {fShortenNumber} from "../../utils/formatNumber";
import {useFindById} from "../backoffice/configuration/home-page/home.page.service";
import {useAllLikeCount} from "../backoffice/general/events/event.like.service";
import {useAllCommentCount} from "../backoffice/general/events/event.comment.service";
import SimpleCircularProgress from "../../shared/components/SimpleCircularProgress";

const gradientBuilder = theme => {
    // const color = red["500"];
    const color = theme.palette.primary.light;
    const color1 = alpha(color, 0.70);
    const color2 = alpha(color, 0.10);
    const color3 = alpha(color, 0.01);
    return `linear-gradient(to bottom, ${color1}, ${color2}, ${color3})`;
}

const RootStyle = styled(Card)(({theme}) => ({
    borderRadius: 0,
    display: "flex",
    background: gradientBuilder(theme),
    boxShadow: theme.shadows[3],
    [theme.breakpoints.up("xs")]: {
        height: 300
    },
    [theme.breakpoints.up("sm")]: {
        height: 450
    },
    [theme.breakpoints.up("md")]: {
        height: 600
    },
    // [theme.breakpoints.up("lg")]: {
    //     height: 900
    // },
}))

const ImageStyle = styled(Box)(({src}) => ({
    width: "100%",
    height: "80%",
    position: "relative",
    backgroundSize: "cover",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    backgroundImage: `url(${src})`,
}));

const RectangleStyle = styled(Stack)(() => ({
    left: "5%",
    height: "30%",
    width: "90%",
    bottom: "-18%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "start",
}));

export default function HeroImage() {
    const {data, isLoading} = useFindById();

    if (isLoading || !data) return <SimpleCircularProgress/>

    const {backgroundImageUrl, title, imageUrl} = data;

    return (
            <RootStyle>
                <Container maxWidth={"xl"}>
                    <ImageStyle src={backgroundImageUrl}>
                        <RectangleStyle direction={"row"} spacing={2}>
                            <CircleImage image={imageUrl}/>
                            <TitleAndMenu title={title}/>
                        </RectangleStyle>
                    </ImageStyle>
                </Container>
            </RootStyle>
    )
}

function CircleImage({image}) {
    const responsiveValue = useMemo(() => ({
        xs: 80,
        sm: 120,
        md: 140,
        lg: 190,
    }), []);

    return (
        <Avatar sx={{
            height: responsiveValue, width: responsiveValue, p: .5,
            backgroundColor: theme => theme.palette.common.white,
        }}>
            <Avatar src={image} sx={{height: "100%", width: "100%", border: "1px solid gray"}}/>
        </Avatar>
    );
}

function TitleAndMenu({title}) {
    return (
        <Stack pt={4}>
            <Typography variant={"h3"} fontSize={{xs: 16, sm: 20, md: 25, lg: 30}}>
                {title}
            </Typography>

            <Typography>
                <AllLikeCount/>
                <Typography component={"span"}>
                    {` • `}
                </Typography>
                <AllCommentCount/>
            </Typography>
        </Stack>
    );
}

function AllLikeCount() {
    const {count, isLoading} = useAllLikeCount();

    return (
        <AllCount count={count} isLoading={isLoading} label={"J’aime"}/>
    )
}

function AllCommentCount() {
    const {count, isLoading} = useAllCommentCount();

    return (
        <AllCount count={count} isLoading={isLoading} label={"commentaires"}/>
    )
}

function AllCount({count, isLoading, label}) {
    if (isLoading) return <></>

    return (
        <Typography component={"span"} fontSize={{
            xs: 12,
            sm: 13,
            md: 14,
            lg: 15,
        }}>
            {`${fShortenNumber(count)} ${label}`}
        </Typography>
    )
}

function HeroImageSkeleton() {
    return (
        <RootStyle>
            <Container maxWidth={"xl"}>

                {/*<Skeleton width="100%" height={"80%"} variant="rectangular"/>*/}

                {/*<Stack>*/}
                {/*    <Skeleton width="10" height={"80%"} variant="circular"/>*/}
                {/*    <Skeleton variant="text" height={20}/>*/}
                {/*</Stack>*/}

                <Skeleton width="100%" height={"80%"} variant="rectangular">
                    <RectangleStyle direction={"row"} spacing={2}>
                        <Skeleton width="10" height={"80%"} variant="circular"/>
                        <Skeleton variant="text" height={20}/>
                    </RectangleStyle>
                </Skeleton>
            </Container>
        </RootStyle>
    )
}
