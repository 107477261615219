import {Container} from "@mui/material";

import {Page} from "../../@client/shared/pages";
import HeroImage from "../../@client/features/@home/HeroImage";
import EventsContainer from "../../@client/features/events/components/EventsContainer";

export default function EventIndex() {

    return (
        <Page title={"Les parcours"} pb={10}>
            <HeroImage/>

            <Container maxWidth={"xl"} sx={{mt: 3}}>
                <EventsContainer/>
            </Container>
        </Page>
    );
}
